import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

type Props = {
  code?: string;
  title: string;
  subtitle: string;
  Icon: any;
  buttonMessage?: string;
  buttonLink?: string;
};

const ScreenError: (props: Props) => any = ({
  code,
  title,
  subtitle,
  Icon,
  buttonMessage,
  buttonLink,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const btnMessage = buttonMessage || t('goBackToHome');
  const btnLink = buttonLink || '/';

  return (
    <div className="container w-5/6 m-auto my-5 md:py-6 flex flex-col md:flex-row-reverse justify-between items-center lg:px-0 pb-12">
      <Image
        src={Icon}
        layout="intrinsic"
        width="550"
        height="520"
        alt="Butaca Error"
      />
      <div className="space-y-4 w-full md:w-2/4">
        <p className="text-3xl md:text-5xl">{code}</p>
        <p className="text-xl md:text-3xl font-normal">{title}</p>
        <p className="text-sm md:text-lg font-thin">{subtitle}</p>
        <Link href={btnLink} locale={locale}>
          <button className="rounded-lg bg-accent px-5 py-3 my-5 text-sm font-light">
            <span>{btnMessage}</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ScreenError;
